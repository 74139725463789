import React from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';

class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.initState();
    }

    initState = () => ({
        sending: false,
        messageSent: false,
        errMessage: '',
        values: {
            firstName: '',
            lastName: '',
            company: '',
            email: '',
            msg: '',
            phone: ''
        }
    })

    onChange = e => this.setState(p => ({ values: { ...p.values, [e.target.id]: e.target.value }, errMessage: '' }))

    sendMessage = e => {
        e.preventDefault();


        if (!this.state.values.firstName)
            return this.setState({ errMessage: 'First Name must not be blank' })

        if (!this.state.values.email)
            return this.setState({ errMessage: 'Email must not be blank' })


        this.setState({ sending: true }, () => {
            Axios.post('/api/v1/inforequest', this.state.values)
                .then(result => this.setState({ messageSent: true }))
                .catch(err => {
                    console.log(err);
                    if (err.response)
                        this.setState({ errMessage: typeof err.response.data === 'string' ? err.response.data : 'An unknown error occurred' })
                    else
                        console.log(err);
                })
                .finally(() => this.setState({ sending: false }))
        })
    }

    render = () => (
        <div>
            <div className="row subnav" style={{ display: 'flex', justifyContent: 'space-around' }}>
                <div style={{ display: 'flex', justifyContent: 'space-evenly', position: 'absolute', bottom: '4px', left: '80px', fontSize: '1.5em' }}>
                    <Link className="white-text" to="/">Home</Link>
                    <span className="grey-text text-darken-2" style={{ marginLeft: '4px', marginRight: '4px', fontWeight: '500' }}>/</span>
                    <span className="grey-text text-darken-2">Contact</span>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <h4>Contact Us</h4>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '20px' }}>
                    <div className="row" style={{ flex: 1 }}>
                        <div className="col s12 m6" style={{ marginBottom: '20px' }}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div className="red lighten-1" style={{ position: 'relative', height: '80px', width: '80px', borderRadius: '50%', display: 'inline-block' }}>
                                    <i className="fas fa-phone white-text" style={{ position: 'absolute', top: '15px', left: '15px', fontSize: '48px' }}></i>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '8px' }}>
                                    <b>PHONE:</b>
                                    <span>(855) OUTDATE, (855) 688-3283</span>
                                    <span>(909) 328-4124</span>
                                </div>
                            </div>
                        </div>
                        <div className="col s12 m6">
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div className="red lighten-1" style={{ position: 'relative', height: '80px', width: '80px', borderRadius: '50%', display: 'inline-block' }}>
                                    <i className="fas fa-envelope white-text" style={{ position: 'absolute', top: '15px', left: '15px', fontSize: '48px' }}></i>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '8px' }}>
                                    <b>Email:</b>
                                    <span>New Customers: <a href="mailto:sales@outdaterx.com">sales@outdaterx.com</a></span>
                                    <span>Careers:<a href="mailto:careers@outdaterx.com">careers@outdaterx.com</a></span>
                                    <span>General Inquiries: <a href="mailto:contact@outdaterx.com">contact@outdaterx.com</a></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {this.state.messageSent ?
                        <div>
                            <h4 className="center green-text green-darken-5">Your message has been sent. Someone from our sales team will contact you soon!</h4>
                        </div>
                        :
                        <form>
                            <div className="row">
                                <div className="input-field col s12 m6">
                                    <input id="firstName" type="text" onChange={this.onChange} value={this.state.values.firstName} />
                                    <label htmlFor="firstName">First Name</label>
                                </div>
                                <div className="input-field col s12 m6">
                                    <input id="lastName" type="text" onChange={this.onChange} value={this.state.values.lastName} />
                                    <label htmlFor="lastName">Last Name</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="input-field col s12 m6">
                                    <input id="company" type="text" onChange={this.onChange} value={this.state.values.company} />
                                    <label htmlFor="company">Company</label>
                                </div>
                                <div className="input-field col s12 m6">
                                    <input id="email" type="text" onChange={this.onChange} value={this.state.values.email} />
                                    <label htmlFor="email">Your Email</label>
                                </div>
                            </div>
                            <div>
                                <div className="input-field col s12 m6">
                                    <input id="phone" type="text" onChange={this.onChange} value={this.state.values.phone} />
                                    <label htmlFor="phone">Your Phone</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="input-field col s12">
                                    <textarea id="msg" className="materialize-textarea" onChange={this.onChange} value={this.state.values.msg} />
                                    <label htmlFor="msg">Message</label>
                                </div>
                            </div>
                            <div>
                                {this.state.sending ?
                                    <div className="row">
                                        <div className="preloader-wrapper small active">
                                            <div className="spinner-layer spinner-red-only">
                                                <div className="circle-clipper left">
                                                    <div className="circle"></div>
                                                </div><div className="gap-patch">
                                                    <div className="circle"></div>
                                                </div><div className="circle-clipper right">
                                                    <div className="circle"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="row">
                                        {this.state.errMessage ?
                                            <h5 className="red-text">{this.state.errMessage}</h5>
                                            :
                                            <div className="col s12 m4 l2">
                                                <button type="submit" className="btn-large red white-text waves-effect col s12" onClick={this.sendMessage}>Send</button>
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                        </form>
                    }
                </div>
            </div>
        </div>
    )
}

export default Contact;